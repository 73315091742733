<template>
  <div class="row">
      <div class="col-sm-12 col-md-5" id="total-count-container">
          <div v-show="showTotal" class="dataTables_info" role="status" aria-live="polite">
              {{$t('pagination.showing', {showing: showing, to: to, total: config.total.toLocaleString()})}}
          </div>
      </div>
      <div class="col-sm-12 col-md-7">
          <div class="dataTables_paginate paging_simple_numbers" style="float: right;">
              <ul class="pagination">
                  <li v-if="config.page - 1 > 4" class="paginate_button page-item previous">
                      <a href="javascript:void(0);" class="page-link" @click="$emit('search', 1)">First</a>
                  </li>
                  <li v-if="config.page - 1 > 0" class="paginate_button page-item previous">
                      <a href="javascript:void(0);" class="page-link" @click="$emit('search', parseInt(config.page) - 1)">{{$t('pagination.previous')}}</a>
                  </li>
                  <li v-if="config.page - 4 > 0" class="paginate_button page-item">
                      <a href="javascript:void(0);" class="page-link" @click="$emit('search', parseInt(config.page) - 4)">{{parseInt(config.page) - 4}}</a>
                  </li>
                  <li v-if="config.page - 3 > 0" class="paginate_button page-item ">
                      <a href="javascript:void(0);" class="page-link" @click="$emit('search', parseInt(config.page) - 3)">{{parseInt(config.page) - 3}}</a>
                  </li>
                  <li v-if="config.page - 2 > 0" class="paginate_button page-item ">
                      <a href="javascript:void(0);" class="page-link" @click="$emit('search', parseInt(config.page) - 2)">{{parseInt(config.page) - 2}}</a>
                  </li>
                  <li v-if="config.page - 1 > 0" class="paginate_button page-item ">
                      <a href="javascript:void(0);" class="page-link" @click="$emit('search', parseInt(config.page) - 1)">{{parseInt(config.page) - 1}}</a>
                  </li>
                  <li class="paginate_button page-item active">
                      <a href="javascript:void(0);" class="page-link">{{parseInt(config.page)}}</a>
                  </li>
                  <li v-if="config.page + 1 <= config.maxPage" class="paginate_button page-item ">
                      <a href="javascript:void(0);" class="page-link" @click="$emit('search', parseInt(config.page) + 1)">{{parseInt(config.page) + 1}}</a>
                  </li>
                  <li v-if="config.page + 2 <= config.maxPage" class="paginate_button page-item ">
                      <a href="javascript:void(0);" class="page-link" @click="$emit('search', parseInt(config.page) + 2)">{{parseInt(config.page) + 2}}</a>
                  </li>
                  <li v-if="config.page + 3 <= config.maxPage" class="paginate_button page-item ">
                      <a href="javascript:void(0);" class="page-link" @click="$emit('search', parseInt(config.page) + 3)">{{parseInt(config.page) + 3}}</a>
                  </li>
                  <li v-if="config.page + 4 <= config.maxPage" class="paginate_button page-item ">
                      <a href="javascript:void(0);" class="page-link" @click="$emit('search', parseInt(config.page) + 4)">{{parseInt(config.page) + 4}}</a>
                  </li>
                  <li v-if="config.page + 1 <= config.maxPage" class="paginate_button page-item next">
                      <a href="javascript:void(0);" class="page-link" @click="$emit('search', parseInt(config.page) + 1)">{{$t('pagination.next')}}</a>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</template>

<script>
export default {

  // page: 0,
  // showing: 0,
  // total: 0,
  // lastPage: 0,
  // maxPage: 0
  name: 'Pagination',
  props: {
      config: Object,
      showTotal: {
          type: Boolean,
          required: false,
          default: true
      }
  },
  computed: {
      showing: function(){
          if(this.config.total === 0)
              return 0;
          return this.config.showing * (this.config.page - 1) + 1;
      },
      to: function(){
          if(this.config.total === 0)
              return 0;
          if(this.config.page === this.config.maxPage)
              return this.config.total;
          return this.config.showing * this.config.page;
      }
  }
}
</script>

<style scoped>
  .row {
      margin-top: 20px;
  }

  #total-count-container {
      position: relative;
  }

  #total-count-container > div {
      position: absolute;
      top: 25%;
  }
</style>